import React from 'react';
import styled from 'styled-components';

import Slider from './Slider';

const getTextRgb = (base) => {
  if (base < 50) return 180;
  if (base < 140) return 230;
  if (base < 180 && base > 130) return 40;
  if (base >= 180) return 80;
}

const Background = styled.header`
  background-color: ${({ color: v }) => `rgb(${v}, ${v}, ${v})`};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span`
  margin: 8px 0;
  font-size: 20px;
  color: ${({ color: v }) => `rgb(${v}, ${v}, ${v})`};
`;

class App extends React.Component {
  state = {
    slider: 255,
  }

  onChange = (event) => {
    this.setState({ slider: parseInt(event.target.value) })
  }

  render() {
    const rgb = this.state.slider;
    const textRgb = getTextRgb(rgb);
    return (
      <div className="App">
        <Background color={rgb} className="App-header">
          <Text color={textRgb}>Drag to dim or brighten</Text>
          <Slider 
            type="range" 
            min="0" 
            max="255" 
            value={this.state.slider} 
            onChange={this.onChange} 
          />
        </Background>
      </div>
    );
  }
}

export default App;
