import styled from 'styled-components';

export default styled.input`
  -webkit-appearance: none;
  width: 400px;
  max-width: 80%;
  margin: 7.3px 0;

  :focus {
    outline: none;
  }
  ::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    box-shadow: 1px  1px #cccccc, 0px 0px 1px #cccccc;
    background: rgba(0, 0, 0, 0.37);
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  ::-webkit-slider-thumb {
    box-shadow: 1px 1px 4px #000000, 0px 0px 1px #0d0d0d;
    border: 1.2px solid rgba(20, 20, 20, 0.39);
    height: 26px;
    width: 26px;
    border-radius: 26px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9.5px;
  }
  :focus::-webkit-slider-runnable-track {
    background: rgba(13, 13, 13, 0.37);
  }
  ::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: rgba(0, 0, 0, 0.37);
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  ::-moz-range-thumb {
    box-shadow: 1px 1px 4px #000000, 0px 0px 1px #0d0d0d;
    border: 1.2px solid rgba(20, 20, 20, 0.39);
    height: 26px;
    width: 26px;
    border-radius: 26px;
    background: #ffffff;
    cursor: pointer;
  }
  ::-ms-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  ::-ms-fill-lower {
    background: rgba(0, 0, 0, 0.37);
    border: 0.2px solid #010101;
    border-radius: 2.6px;
  }
  ::-ms-fill-upper {
    background: rgba(0, 0, 0, 0.37);
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  ::-ms-thumb {
    box-shadow: 1px 1px 4px #000000, 0px 0px 1px #0d0d0d;
    border: 1.2px solid rgba(20, 20, 20, 0.39);
    height: 26px;
    width: 26px;
    border-radius: 26px;
    background: #ffffff;
    cursor: pointer;
    height: 11.4px;
  }
  :focus::-ms-fill-lower {
    background: rgba(0, 0, 0, 0.37);
  }
  :focus::-ms-fill-upper {
    background: rgba(13, 13, 13, 0.37);
  }
`;